body {
    margin: 0;
    padding: 0;
    font-size: 12px;
    background: #f8f8f8;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    android: clipToPadding="true";
  
    android: fitsSystemWindows="true";
    -moz-osx-font-smoothing: grayscale;
    position: relative;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  form,
  input,
  fieldset,
  legend,
  img,
  select {
    margin: 0;
    padding: 0;
  }
  
  ul,
  ol,
  li {
    list-style: none;
  }
  
  a {
    text-decoration: none;
    color: #333;
  
  }
  
  img {
    display: block;
    border: 0;
  }
  
  section,
  nav,
  header,
  footer,
  aside {
    display: block;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
  
  strong,
  b {
    font-weight: normal;
  }
  
  i,
  em {
    font-style: normal;
  }
  
  .fl {
    float: left;
  }
  
  .fr {
    float: right;
  }
  
  .clear:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  
  .clear {
    zoom: 1;
  }
  
  .top{
    width: 100%;
    height: 4vw;
    position: fixed;
    background-color: #000;
    top: 0;
    left: 0;
    z-index: 999;
    .mark {

      width: 2.6vw;
      height: 1.6vw;
      background: url(./pages/Home/bird.png) 2.6vw 1.6vw;
      background-size: 2.6vw 1.6vw;
      position: absolute;
      left: 4vw;
      top: 1.3vw;

   }

   .nav {
      font-size: 0.7vw;
      position: absolute;
      top: 1.6vw;
      right: 4vw;
      color: #fff;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;

      .aim {
          font-size: 0.7vw;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #8D8D8D;
      }
   }
  }