
@media screen and (orientation: landscape) {
    

.homeone {
    width: 100vw;
    height: 100vh;
    background: url(./home1.png) 100vw 100vh;
    background-size: 100vw 100vh;
    overflow: hidden;
    position: relative;

    

    .lib {
        width: 4px;
        height: 56px;
        background: url(./lib.png) 4px 56px;
        background-size: 4px 56px;
        position: absolute;
        left: 4vw;
        top: 42vh;
    }

    .content{

        animation:fadenum 2s;
        
        }
        
        @keyframes fadenum{
        
          0%{transform:translateY(50px);
        opacity: 0;}

        
        }

    .content {
        width: 60%;
        height: 19.3vw;
        margin: auto;
        margin-top: 15vh;
        display: flex;
        justify-content: space-between;

        .content-left {
            width: 30.5vw;

            h2 {
                margin-top: 3.3vw;
                font-size: 3.3vw;
                font-family: MiSans-Semibold, MiSans;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 3.9vw;
            }

            p {
                font-size: 0.8vw;
                font-family: MiSans-Regular, MiSans;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 1vw;
                margin-top: 3vw;
            }

            .download {
                margin-top: 7vw;
                display: flex;
                width: 18vw;
                height: 2.8vw;
                justify-content: space-between;



                .ant-btn {
                    width: 8.5vw;
                    height: 2.8vw;
                    background: #89F787;
                    border-radius: 1.4vw;
                    border: none;
                    color: #333;

                }

                .ant-btn:hover {
                    margin-top: -5px;
                    box-shadow: 0 3px 15px 3px rgba(137, 247, 135, 0.7);
                    cursor: pointer;
                }



            }

            .bottom {
                width: 105px;
                height: 40px;
                display: flex;
                justify-content: space-between;
                margin-top: 50px;

                div {
                    width: 40px;
                    height: 40px;
                    img{
                        width: 100%;
                    }
                }
            }
        }

        .content-right {
            width: 16.8vw;
            height: 34.6vw;

            background: url(./phone.png) 16.8vw 34.6vw;
            background-size: 16.8vw 34.6vw;
        }
    }
}
}


@media screen and (orientation: portrait) {
    

    .homeone {
        width: 100vw;
        height: 100vh;
        background: url(./home1.png) 100vw 100vh;
        background-size: 100vw 100vh;
        background-color: #89F787;
        overflow: hidden;
        position: relative;
    
        .mark {
    
            width: 5.7vw;
            height: 3.2vw;
            background: url(./bird.png) 5.7vw 3.2vw ;
            background-size: 5.6vw 3.2vw;
            position: absolute;
            left: 4vw;
            top: 2.6vw;
    
        }
    
        .nav {
            font-size: 1.4vw;
            position: fixed;
            top: 3.2vw;
            right: 8vw;
            color: #fff;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
    
            .aim {
                font-size: 1.4vw;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #8D8D8D;
            }
        }
    
        .lib {
            width: 4px;
            height: 56px;
            background: url(./lib.png) 4px 56px;
            background-size: 4px 56px;
            position: absolute;
            left: 4vw;
            top: 42vh;
        }
    
        .content {
            width: 60%;
            height: 100vw;
            margin: auto;
            margin-top: 15vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
    
            .content-left {
                width: 60vw;
    
                h2 {
                    // margin-top: 6.6vw;
                    font-size: 10vw;
                    font-family: MiSans-Semibold, MiSans;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 7.8vw;
                }
    
                p {
                    font-size: 4vw;
                    font-family: MiSans-Regular, MiSans;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 8vw;
                    margin-top: 3vw;
                }
    
                .download {
                    margin-top: 4vw;
                    margin-left: -7.5vw;
                    display: flex;
                    width: 75vw;
                    height: 2.8vw;
                    justify-content: space-between;
    
    
    
                    .ant-btn {
                        width: 30vw;
                        height: 8.4vw;
                        background: #89F787;
                        
                        border-radius: 4.2vw;
                        border: none;
                        font-size: 25px;
    
                    }
    
                    .ant-btn:hover {
                        margin-top: -5px;
                        box-shadow: 0 3px 15px 3px rgba(137, 247, 135, 0.7);
                        cursor: pointer;
                        
                    }
    
    
    
                }
    
                .bottom {
                    width: 105px;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 50px;
                    display: none;
    
                    div {
                        width: 40px;
                        height: 40px;
                        img{
                            width: 100%;
                        }
                    }
                }
            }
    
            .content-right {
                width: 16.8vw;
                height: 34.6vw;
    
                background: url(./phone.png) 16.8vw 34.6vw;
                background-size: 16.8vw 34.6vw;
            }
        }
    }
    }