.hometwo {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    .lib{
        width: 4px;
        height: 56px;
        background: url(./lib.png) 4px 56px;
        background-size: 4px 56px;
        position: absolute;
        left: 4vw;
        top: 42vh;
    }
    .content{

        animation:fadenum 2s;
        
        }
        
        @keyframes fadenum{
        
          0%{transform:translateY(50px);
        opacity: 0;}

        
        }


    .content {
        width: 60%;
        height: 19.3vw;
        margin: auto;
        margin-top: 15vh;
        display: flex;
        justify-content: space-between;

        .content-left {
            width: 30.5vw;

            h2 {
                margin-top: 3.3vw;
                font-size: 3.3vw;
                font-family: MiSans-Semibold, MiSans;
                font-weight: 600;
                color: #333;
                line-height: 3.9vw;
            }

            p {
                font-size: 0.8vw;
                font-family: MiSans-Regular, MiSans;
                font-weight: 400;
                color: #333;
                line-height: 1vw;
                margin-top: 3vw;
            }

            .download {
                margin-top: 7vw;
                display: flex;
                width: 18vw;
                height: 2.8vw;
                justify-content: space-between;

                .ant-btn {
                    width: 8.5vw;
                    height: 2.8vw;
                    background: #89F787;
                    border-radius: 1.4vw;
                    border: none;
                    color: #000000;
                    font-size: 0.9vw;
                }
                .ant-btn:hover{
                    margin-top: -5px;
                    box-shadow: 0 3px 15px 3px rgba(137,247,135,0.7);
                }




            }
        }

        .content-right {
            width: 331px;
            height: 430px;
            background: url(./book.png) 331px 443px;
            background-size: 331px 443px;
        }
    }
}